import * as React from "react"
import { useTranslation } from "react-i18next"
import LocalizationConstant from "../../i18n/LocalizationConstant"

export function Footer() {
  const { t } = useTranslation()
  return (
    <>
      {/* show little footer on staging or dev */}
      {process.env.GATSBY_API_URL &&
        (process.env.GATSBY_API_URL.indexOf("staging") !== -1 ||
          process.env.GATSBY_API_URL.indexOf("21021") !== -1) && (
          <footer>
            <div className="d-flex flex-column align-items-center justify-content-around tiny text-light">
              <div>{t(LocalizationConstant.PEEPO_STAGING)}</div>
              <div>{t(LocalizationConstant.LAST_BUILT)}{": "}
              {process.env.GATSBY_LAST_BUILD?.split("+")[0].replace("T", " ")}</div>
            </div>
          </footer>
        )}
    </>
  )
}
