import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

export function BackgroundImage() {
  return (
    <StaticImage
      className="h-100 w-100 position-fixed"
      layout="fullWidth"
      src="../../images/background.png"
      alt={"background"}
    />
  )
}
