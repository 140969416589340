import * as React from "react"
import "../../i18n/i18n"
import { PageProps } from "gatsby"
import { BackgroundImage } from "./BackgroundImage"
import { Footer } from "./Footer"

interface ILayoutProps {
  pageProps: PageProps
}

const Layout = (props: React.PropsWithChildren<ILayoutProps>) => {
  const { children } = props

  return (
    <>
      <BackgroundImage />
      <div className="layout position-relative">{children}</div>
      <Footer />
    </>
  )
}

export default Layout
