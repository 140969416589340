import * as React from "react"

interface ITitleProps {
  title: string
  subtitles: Array<string>
}

export function Title(props: ITitleProps) {
  const { title, subtitles } = props
  return (
    <div className="d-flex flex-column justify-content-center align-items-center m-5">
      <h1 className="text-center text-light">{title}</h1>
      <div className="subtitles">
        {subtitles.map((subtitle, index) => {
          return (
            <p key={index} className="text-center text-light">
              {subtitle}
            </p>
          )
        })}
      </div>
    </div>
  )
}
