const LocalizationConstant = {
  HOME_PAGE_TITLE: 'HOME_PAGE_TITLE',
  PARTICIPATION_PAGE_TITLE: 'PARTICIPATION_PAGE_TITLE',
  RESULT_PAGE_TITLE: 'RESULT_PAGE_TITLE',
  NOT_FOUND_PAGE_TITLE: 'NOT_FOUND_PAGE_TITLE',
  HOME_TITLE: 'HOME_TITLE',
  HOME_DESCRIPTION: 'HOME_DESCRIPTION',
  PARTICIPATION_TITLE: 'PARTICIPATION_TITLE',
  HOME_DESCRIPTION_ONE: 'HOME_DESCRIPTION_ONE',
  HOME_DESCRIPTION_TWO: 'HOME_DESCRIPTION_TWO',
  NOT_FOUND_TITLE: 'NOT_FOUND_TITLE',
  NOT_FOUND_DESCRIPTION: 'NOT_FOUND_DESCRIPTION',
  NOT_FOUND_BUTTON_TEXT: 'NOT_FOUND_BUTTON_TEXT',
  STEP_ONE: 'STEP_ONE',
  STEP_TWO: 'STEP_TWO',
  STEP_THREE: 'STEP_THREE',
  TITLE: 'TITLE',
  PLEASE_PROVIDE_YOUR_TITLE: 'PLEASE_PROVIDE_YOUR_TITLE',
  LOCATION: 'LOCATION',
  NOTE: 'NOTE',
  NAME: 'NAME',
  YOUR_NAME: 'YOUR_NAME',
  EMAIL: 'EMAIL',
  CONTINUE: 'CONTINUE',
  OPTIONAL: 'OPTIONAL',
  PLEASE_PROVIDE_A_VALID_EMAIL: 'PLEASE_PROVIDE_A_VALID_EMAIL',
  PLEASE_PROVIDE_YOUR_EMAIL: 'PLEASE_PROVIDE_YOUR_EMAIL',
  PLEASE_PROVIDE_FIRST_NAME: 'PLEASE_PROVIDE_FIRST_NAME',
  PLEASE_PROVIDE_YOUR_FIRST_NAME: 'PLEASE_PROVIDE_YOUR_FIRST_NAME',
  DATE: 'DATE',
  PLEASE_PROVIDE_A_DATE: 'PLEASE_PROVIDE_A_DATE',
  TIME: 'TIME',
  PLEASE_PROVIDE_YOUR_TIME: 'PLEASE_PROVIDE_YOUR_TIME',
  FORM_ONE_TITLE: 'FORM_ONE_TITLE',
  FORM_TWO_TITLE: 'FORM_TWO_TITLE',
  FORM_THREE_TITLE: 'FORM_THREE_TITLE',
  FORM_THREE_SUBTITLE: 'FORM_THREE_SUBTITLE',
  FINISHED_TITLE: 'FINISHED_TITLE',
  FINISHED_DESCRIPTION: 'FINISHED_DESCRIPTION',
  DO_YOU_HAVE_ANOTHER_PROPOSITION: 'DO_YOU_HAVE_ANOTHER_PROPOSITION',
  CREATE_ANOTHER_PEEPO: 'CREATE_ANOTHER_PEEPO',
  PLANNED_COMMA: 'PLANNED_COMMA',
  HAS_PLANNED_THE_FOLLOWING: 'HAS_PLANNED_THE_FOLLOWING',
  JOIN_NOW: 'JOIN_NOW',
  LET_THE_PARTICIPANTS_KNOW: 'LET_THE_PARTICIPANTS_KNOW',
  THE_PEEPO_IS_FOR: 'THE_PEEPO_IS_FOR',
  MUST_ORGANIZE_THE_FOLLOWING: 'MUST_ORGANIZE_THE_FOLLOWING',
  PEEPO_STAGING: 'PEEPO_STAGING',
  LAST_BUILT: 'LAST_BUILT',
  WE_WONT_LET_YOU_ALONE: 'WE_WONT_LET_YOU_ALONE',
  CONCIERGE_SERVICE: 'CONCIERGE_SERVICE',
  ORGANIZE_IN_TWO_MINUTES: 'ORGANIZE_IN_TWO_MINUTES',
  NO_ADS: 'NO_ADS',
  ADD_TO_THE_WAITING_LIST: 'ADD_TO_THE_WAITING_LIST',
  COPIED: 'COPIED',
  COPY_LINK: 'COPY_LINK',
  PARTICIPATE: 'PARTICIPATE',
  THANK_YOU: 'THANK_YOU',
  THANK_YOU_FOR_PARTICIPATING: 'THANK_YOU_FOR_PARTICIPATING',
  UNFORTUNATELY_NO_PARTICIPANTS: 'UNFORTUNATELY_NO_PARTICIPANTS',
  CONGRATS_YOU_ARE_THE_FIRST_TO_PARTICIPATE: 'CONGRATS_YOU_ARE_THE_FIRST_TO_PARTICIPATE',
  SO_FAR_FOLLOWING_PEOPLE_HAVE_PARTICIPATED: 'SO_FAR_FOLLOWING_PEOPLE_HAVE_PARTICIPATED',
  FOLLOWING_PEOPLE_PARTICIPATED: 'FOLLOWING_PEOPLE_PARTICIPATED',
  AND: 'AND'
}

export default LocalizationConstant
